

.art:hover {
  cursor: pointer;
}

.play-button:hover {
  filter: saturate(50%);
  cursor: pointer;
}

.viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5vh;
  gap: 1.5vh;
  width: auto;

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }

  > h1 {
    font-size: 4vh !important;
  }

  > h2 {
    font-size: 3vh !important;
  }

  > p {
    font-size: 2.5vh;
  }

  .artist-text {
    color: #333;
    font-weight: 300;
  }

  > img {
    height: 70vh;
    pointer-events: none;
  }
}

// MUSIC SHOP

.song-selection {
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  z-index: 300;

  > h2 {
    margin: 0;
    font-weight: 200;
    pointer-events: none;
  }
}

.music-selection-ui {
  margin-top: 7vh;

  > div {

    > p {
      margin-top: 0.6vh;
      margin-bottom: 0.6vh;
    }
  }

  > div:hover {
    cursor: pointer;
    color: rgb(138, 112, 42);
  }
}

.music-player {
  //background-color: #fffa;
  border-radius: 10px;
  padding: 0.6vh;
  position: absolute;
  text-align: center;

  > p {
    margin: 0;
    font-size: 1.8vh;
  }

  > div {
    background: transparent;
    box-shadow: none;
  }

  .playlist-button {
    //position: absolute;
    height: 4.5vh;
    cursor: pointer;
    image-rendering: -moz-crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
.rhap_container {
  .rhap_time {
    font-size: 1.8vh !important;
  }
  .rhap_controls-section {
    margin-top: 1vh !important;
    justify-content: center;
    gap: 1vh;
  }
  .rhap_main-controls-button {
    width: 4vh;
    height: 4vh;
    display: grid;
    place-items: center;
    margin: 0;

    > svg {
      width: 4vh;
      height: 4vh;
    }
  }

  .rhap_volume-bar-area {
    display: none;
  }

  .rhap_progress-indicator {
    width: 1.8vh;
    height: 1.8vh;
    top: -0.6vh;
  }
}

// FOYER

.video-selection {
  z-index: 300;
  background-repeat: no-repeat;
  &:hover {
    filter: saturate(3);
  }
}

.video-selection-ui {

  > div {
    > p {
      margin: 2vh;
      font-size: 3vh;
    }
  }

  > div:hover {
    cursor: pointer;
    color: rgb(182, 7, 7);
  }
}


// THEATRE

.lights-out {
  background-color: #000d;
  z-index: 200;
  transition: background-color 2s linear;
}

.lights-on {
  background-color: #0000;
  z-index: 200;
  transition: background-color 2s linear;
}

@keyframes fadelight {
  0% {
    box-shadow: 0 100px 200px 100px rgba(255, 255, 255, 0.17);
  };
  50% {
    box-shadow: 0 100px 200px 100px rgba(255, 255, 255, 0.24);
  };
  70% {
    box-shadow: 0 100px 200px 100px rgba(255, 255, 255, 0.19);
  };
  100% {
    box-shadow: 0 100px 200px 100px rgba(255, 255, 255, 0.26);
  }
}