.vote-selection {
    display: flex;
    gap: 2vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
    margin: 0 auto 0;
    padding-top: 8vh;
    text-align: center;

    p {
        font-size: 2.2vh;
        margin: 0;
    }

    h2 {
        font-size: 3.6vh;
        margin: 0;
    }

    .vote-item {
        cursor: pointer;
    }
}

.vote-again,.vote-submit {
    padding: 1vh 3vh;
    font-weight: 600;
    background-color: rgb(5, 163, 0);
    color: #fff;
    text-align: center;
    font-size: 3vh;
    border-radius: 3vh;
  
    &:hover {
      background-color: rgb(0, 136, 52);
      cursor: pointer;
    }
}

.vote-end {
  padding: 30vh 10vh 0 10vh;
  box-sizing: border-box;
  text-align: center;

  > h2 {
    font-size: 5vh;
    margin: 0;
  }

  > p {
    font-size: 2.6vh;
  }
}