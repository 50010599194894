@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&family=Noto+Sans&family=Roboto&display=swap');

body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  background: linear-gradient(90deg, rgb(9, 9, 80) 0%, #000 50%, rgb(9, 9, 80) 100%) rgb(5, 5, 65);
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
}


* {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-message {
  font-family: 'Cabin', sans-serif;
}

.custom-shape-divider-bottom-1637522500 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1637522500 svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: max(10vh, 10vw);
}

.custom-shape-divider-bottom-1637522500 .shape-fill {
    fill: #FFFFFF;
}