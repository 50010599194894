.character-customisation {
  display: flex;
  justify-content: space-between;
  text-align: center;

  .left {
    height: 70vh;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .character-viewer {
      height: 100%;
      position: relative;

      > div {
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

    }

    .colour-selection {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: min(3vh, 3vw);
      width: 80%;
      margin: 0 auto 0;

      .colour-choice {
        width: min(4vh, 4vw);
        height: min(4vh, 4vw);
        border-radius: 2vh;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .right {
    width: 55%;
    padding: 3vh 0;
    padding-right: min(3vh, 3vw);

    h3 {
      font-size: 3vh;
    }

    .acc-selection {
      display: flex;
      flex-wrap: wrap;
      gap: 3vh;
      justify-content: center;

      .acc-item {
        background-color: rgba(122, 122, 122, 0.1);
        border-radius: 2vh;
        width: 14vh;
        height: 14vh;
        background-position: center;
        background-size: 180% auto;
        background-repeat: no-repeat;

        &:hover {
          cursor: pointer;
          box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
        }
      }
    }
  }
}

.save-customisation {
  margin: 0 auto 0;
  padding: 1vh 3vh;
  font-weight: 600;
  background-color: rgb(5, 163, 0);
  color: #fff;
  width: 6vh;
  text-align: center;
  font-size: 3vh;
  border-radius: 1vh;

  &:hover {
    background-color: rgb(0, 136, 52);
    cursor: pointer;
  }
}