.character {
  position: absolute;
  background-position-y: center;
  //background-size: 120%;
  pointer-events: none;
  background-repeat: no-repeat;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

