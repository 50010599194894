.scene-container {
  position: relative;
  background-size: contain;
}

.scene-door {
  height: 10vh;
  width: 10vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.scene-door:hover {
  cursor: pointer;
}

.thumb-horizontal {
  background-color: rgba(255, 255, 255, 0.699);
  border-radius: 10px;
}

.thumb-horizontal:hover {
  background-color: rgba(255, 255, 255, 0.932);
  cursor: pointer;
}

.track-horizontal {
  bottom: 0;
  width: 100%;
  height: 15px !important;
  padding: 2px 5px;
  box-sizing: border-box;
}